<script setup lang="ts">
const { isOwner } = storeToRefs(useUserStore())
</script>

<template>
  <div>
    <YscLoadingBar />
    <AppHeaderContainer
      class="py-2.5 md:py-5"
      container="container-dashboard"
    >
      <template #end>
        <AppHeaderMenu show-app-link />
      </template>
    </AppHeaderContainer>

    <AppMenuDashboard />

    <main>
      <slot />
    </main>

    <AppFooter container="container-dashboard" />

    <ClientOnly v-if="!useFeatureFlag('hideHelp')">
      <AppHelp :active-tab="isOwner ? 'owner' : 'guest'" />
    </ClientOnly>
  </div>
</template>
